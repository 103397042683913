body {
  background: #071123;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #071123;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

.active {
  position: relative;
  z-index: 0;
  overflow: hidden;
  border-radius: 3px;
}

.active::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: 100%100%, 50%50%;
  background-position: 0 0, 100% 0, 100% 100%, 0 100%;
  /* background-image: linear-gradient(#132243, #687aff); */
  animation: bgRotate 4s linear infinite;
  border-radius: 3px;
}

.active::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 1px;
  top: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: linear-gradient(#132243, #132243);
  border-radius: 3px;
}

.inactive::before {
  content: "";
  position: absolute;
  z-index: -2;
  left: -50%;
  top: -50%;
  background-color: #132243;
  border-radius: 3px;
}

.inactive::after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 1px;
  top: 1px;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background: linear-gradient(#132243, #132243);
  border-radius: 3px;
}

.active.heat::before {
  background-image: linear-gradient(#132243, #EEAF4E);
}

.active.cool::before {
  background-image: linear-gradient(#132243, #687aff);
  
}

@keyframes bgRotate {
  100% {
    transform: rotate(1turn);
  }
}
